@font-face {
  font-family: "De Valencia";
  src: url("./assets/fonts/De\ Valencia.otf") format("opentype");
}

@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');

body {
  margin: 0;
  font-family: 'Spartan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  margin: 0;
}