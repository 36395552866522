#details {
    width: 100%;
    height: calc(100% - 15vh);

    padding: 0 5em 5em;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
}

#details > .back-button {
    background: none;
    border: none;

    font-family: "De Valencia", sans-serif;
    font-size: 1.5em;
    color: black;

    cursor: pointer;

    align-self: flex-start;
}

#details > .back-button > i {
    margin-right: .8em;
}

#details > h1 {
    font-family: "De Valencia", sans-serif;
    font-size: 4em;
    font-weight: normal;
    text-align: center;

    margin-top: 0;
    margin-bottom: 1em;
}

#details > .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10em;

    padding: 0 5em;

    flex-grow: 1;

    min-height: 0;
}

#details > .content > .text {
    font-family: "Anonymous Pro", sans-serif;
    font-size: 1.3em;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#details > .content > .text > .links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#details > .content > .text > .links > a,
#details > .content > .text > .links > p {
    text-decoration: none;
    color: black;
    
    font-family: "De Valencia", sans-serif;
    font-size: 1.3em;

    margin-top: 1em;
    margin-bottom: 0;
}

#details > .content > .media {
    width: 100%;
    height: 100%;
    min-height: 50vh;

    border-radius: 1em;

    overflow: hidden;
}

#details > .content > .media > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#details > .content > .media > video {
    width: 100%;
    height: 100%;
    background-color: #222222;
}

@media screen and (max-aspect-ratio: 7 / 4) {
    #details {
        padding: 0 4em 4em;
    }

    #details > h1 {
        font-size: 3.5em;
        margin-bottom: .8em;
    }

    #details > .content {
        column-gap: 6em;
    }

    #details > .content > .text {
        font-size: 1.2em;
    }
}

@media screen and (max-aspect-ratio: 7 / 6) {
    #details > .content {
        grid-template-columns: 1fr;
        row-gap: 5em;
    }

    #details > .content > .media {    
        border-radius: 0;
        min-height: 0;
    }

    #details > .content > .media > img {
        border-radius: 1em;
        height: 30vh;
    }

    #details > .content > .media > video {
        border-radius: 1em;
        background: none;
        height: auto;
    }
}

@media screen and (max-aspect-ratio: 4 / 5) {
    #details > .content {
        padding: 0;
    }
}

@media screen and (max-aspect-ratio: 3 / 4) {
    #details {
        padding: 0 2em 2em;
    }

    #details > h1 {
        font-size: 2.8em;
        margin: .7em 0 .5em;
    }

    #details > .content > .text {
        font-size: 1.1em;
    }

    #details > .back-button {
        font-size: 1.3em;
    }
}