#app {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
}

@media screen and (max-aspect-ratio: 7 / 4) {
    #app {
        height: auto;
    }
}