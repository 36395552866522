@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap);
@font-face {
  font-family: "De Valencia";
  src: url("/static/media/De Valencia.cd771d86.otf") format("opentype");
}

body {
  margin: 0;
  font-family: 'Spartan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  margin: 0;
}
#grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 2em;

    width: 100%;
    height: calc(100% - 15vh);

    padding: 0 4em 4em;
    box-sizing: border-box;
}

@media screen and (max-aspect-ratio: 7 / 4) {
    #grid {
        grid-template-rows: repeat(6, 13vh);
    }
}

@media screen and (max-aspect-ratio: 1 / 1) {
    #grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(8, 13vh);
    }
}

@media screen and (max-aspect-ratio: 3 / 4) {
    #grid {
        padding: 0 2em 2em;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, 20vh);
    }
}
#header {
    width: 100%;
    height: 15vh;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    padding: 0 4em;
    box-sizing: border-box;
}

#header > span {
    font-family: 'De Valencia', sans-serif;
    font-size: 1.7em;
    text-transform: uppercase;
}

@media screen and (max-aspect-ratio: 3 / 4) {
    #header {
        padding: 0 2em;
    }

    #header > span {
        font-size: 1.5em;
    }
}
.tile {
    position: relative;
    border-radius: .7em;
    overflow: hidden;
    cursor: pointer;
}

.tile.champia {
    grid-area: 1 / 1 / 5 / 3;
}

.tile.khet {
    grid-area: 1 / 3 / 5 / 4;
}

.tile.instabus {
    grid-area: 1 / 4 / 3 / 6;
}

.tile.audium {
    grid-area: 3 / 4 / 7 / 6;
}

.tile.kula {
    grid-area: 1 / 6 / 4 / 8;
}

.tile.supweather {
    grid-area: 5 / 1 / 7 / 4;
}

.tile.danslemeal {
    grid-area: 4 / 6 / 7 / 8;
}

.tile > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tile > .description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    padding: 2em;

    background-color: white;
}

.tile > .description > h1 {
    margin: 0;

    font-family: 'De Valencia', sans-serif;
    font-weight: normal;
    text-align: center;
    font-size: 2.5em;
}

.tile > .description > p {
    font-family: 'Anonymous Pro', sans-serif;
    text-align: center;
    font-size: 1.2em;
}

.tile > .description > span {
    font-family: 'De Valencia', sans-serif;
    text-align: center;
    font-size: 1.2em;
}

@media screen and (max-aspect-ratio: 7 / 4) {
    .tile.champia {
        grid-area: 1 / 1 / 3 / 4;
    }
    
    .tile.khet {
        grid-area: 3 / 1 / 5 / 4;
    }

    .tile > .description > h1 {
        font-size: 2em;
    }
    
    .tile > .description > p {
        font-size: 1.2em;
    }
    
    .tile > .description > span {
        font-size: 1.2em;
    }
}

@media screen and (max-aspect-ratio: 1 / 1) {
    .tile.champia {
        grid-area: 1 / 1 / 4 / 2;
    }
    
    .tile.khet {
        grid-area: 3 / 2 / 5 / 3;
    }
    
    .tile.instabus {
        grid-area: 7 / 2 / 9 / 3;
    }
    
    .tile.audium {
        grid-area: 1 / 2 / 3 / 3;
    }
    
    .tile.kula {
        grid-area: 6 / 1 / 9 / 2;
    }
    
    .tile.supweather {
        grid-area: 4 / 1 / 6 / 2;
    }
    
    .tile.danslemeal {
        grid-area: 5 / 2 / 7 / 3;
    }

    .tile > .description > h1 {
        font-size: 1.8em;
    }
    
    .tile > .description > p {
        font-size: 1.1em;
    }
    
    .tile > .description > span {
        font-size: 1.1em;
    }
}

@media screen and (max-aspect-ratio: 3 / 4) {
    .tile.champia,
    .tile.khet,
    .tile.instabus,
    .tile.audium,
    .tile.kula,
    .tile.supweather,
    .tile.danslemeal {
        grid-area: auto;
    }
}
#details {
    width: 100%;
    height: calc(100% - 15vh);

    padding: 0 5em 5em;
    box-sizing: border-box;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

#details > .back-button {
    background: none;
    border: none;

    font-family: "De Valencia", sans-serif;
    font-size: 1.5em;
    color: black;

    cursor: pointer;

    -webkit-align-self: flex-start;

            align-self: flex-start;
}

#details > .back-button > i {
    margin-right: .8em;
}

#details > h1 {
    font-family: "De Valencia", sans-serif;
    font-size: 4em;
    font-weight: normal;
    text-align: center;

    margin-top: 0;
    margin-bottom: 1em;
}

#details > .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10em;
    -webkit-column-gap: 10em;
            column-gap: 10em;

    padding: 0 5em;

    -webkit-flex-grow: 1;

            flex-grow: 1;

    min-height: 0;
}

#details > .content > .text {
    font-family: "Anonymous Pro", sans-serif;
    font-size: 1.3em;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

#details > .content > .text > .links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

#details > .content > .text > .links > a,
#details > .content > .text > .links > p {
    text-decoration: none;
    color: black;
    
    font-family: "De Valencia", sans-serif;
    font-size: 1.3em;

    margin-top: 1em;
    margin-bottom: 0;
}

#details > .content > .media {
    width: 100%;
    height: 100%;
    min-height: 50vh;

    border-radius: 1em;

    overflow: hidden;
}

#details > .content > .media > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#details > .content > .media > video {
    width: 100%;
    height: 100%;
    background-color: #222222;
}

@media screen and (max-aspect-ratio: 7 / 4) {
    #details {
        padding: 0 4em 4em;
    }

    #details > h1 {
        font-size: 3.5em;
        margin-bottom: .8em;
    }

    #details > .content {
        grid-column-gap: 6em;
        -webkit-column-gap: 6em;
                column-gap: 6em;
    }

    #details > .content > .text {
        font-size: 1.2em;
    }
}

@media screen and (max-aspect-ratio: 7 / 6) {
    #details > .content {
        grid-template-columns: 1fr;
        grid-row-gap: 5em;
        row-gap: 5em;
    }

    #details > .content > .media {    
        border-radius: 0;
        min-height: 0;
    }

    #details > .content > .media > img {
        border-radius: 1em;
        height: 30vh;
    }

    #details > .content > .media > video {
        border-radius: 1em;
        background: none;
        height: auto;
    }
}

@media screen and (max-aspect-ratio: 4 / 5) {
    #details > .content {
        padding: 0;
    }
}

@media screen and (max-aspect-ratio: 3 / 4) {
    #details {
        padding: 0 2em 2em;
    }

    #details > h1 {
        font-size: 2.8em;
        margin: .7em 0 .5em;
    }

    #details > .content > .text {
        font-size: 1.1em;
    }

    #details > .back-button {
        font-size: 1.3em;
    }
}
#app {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
}

@media screen and (max-aspect-ratio: 7 / 4) {
    #app {
        height: auto;
    }
}
