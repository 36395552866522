.tile {
    position: relative;
    border-radius: .7em;
    overflow: hidden;
    cursor: pointer;
}

.tile.champia {
    grid-area: 1 / 1 / 5 / 3;
}

.tile.khet {
    grid-area: 1 / 3 / 5 / 4;
}

.tile.instabus {
    grid-area: 1 / 4 / 3 / 6;
}

.tile.audium {
    grid-area: 3 / 4 / 7 / 6;
}

.tile.kula {
    grid-area: 1 / 6 / 4 / 8;
}

.tile.supweather {
    grid-area: 5 / 1 / 7 / 4;
}

.tile.danslemeal {
    grid-area: 4 / 6 / 7 / 8;
}

.tile > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tile > .description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 2em;

    background-color: white;
}

.tile > .description > h1 {
    margin: 0;

    font-family: 'De Valencia', sans-serif;
    font-weight: normal;
    text-align: center;
    font-size: 2.5em;
}

.tile > .description > p {
    font-family: 'Anonymous Pro', sans-serif;
    text-align: center;
    font-size: 1.2em;
}

.tile > .description > span {
    font-family: 'De Valencia', sans-serif;
    text-align: center;
    font-size: 1.2em;
}

@media screen and (max-aspect-ratio: 7 / 4) {
    .tile.champia {
        grid-area: 1 / 1 / 3 / 4;
    }
    
    .tile.khet {
        grid-area: 3 / 1 / 5 / 4;
    }

    .tile > .description > h1 {
        font-size: 2em;
    }
    
    .tile > .description > p {
        font-size: 1.2em;
    }
    
    .tile > .description > span {
        font-size: 1.2em;
    }
}

@media screen and (max-aspect-ratio: 1 / 1) {
    .tile.champia {
        grid-area: 1 / 1 / 4 / 2;
    }
    
    .tile.khet {
        grid-area: 3 / 2 / 5 / 3;
    }
    
    .tile.instabus {
        grid-area: 7 / 2 / 9 / 3;
    }
    
    .tile.audium {
        grid-area: 1 / 2 / 3 / 3;
    }
    
    .tile.kula {
        grid-area: 6 / 1 / 9 / 2;
    }
    
    .tile.supweather {
        grid-area: 4 / 1 / 6 / 2;
    }
    
    .tile.danslemeal {
        grid-area: 5 / 2 / 7 / 3;
    }

    .tile > .description > h1 {
        font-size: 1.8em;
    }
    
    .tile > .description > p {
        font-size: 1.1em;
    }
    
    .tile > .description > span {
        font-size: 1.1em;
    }
}

@media screen and (max-aspect-ratio: 3 / 4) {
    .tile.champia,
    .tile.khet,
    .tile.instabus,
    .tile.audium,
    .tile.kula,
    .tile.supweather,
    .tile.danslemeal {
        grid-area: auto;
    }
}