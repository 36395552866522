#grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 2em;

    width: 100%;
    height: calc(100% - 15vh);

    padding: 0 4em 4em;
    box-sizing: border-box;
}

@media screen and (max-aspect-ratio: 7 / 4) {
    #grid {
        grid-template-rows: repeat(6, 13vh);
    }
}

@media screen and (max-aspect-ratio: 1 / 1) {
    #grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(8, 13vh);
    }
}

@media screen and (max-aspect-ratio: 3 / 4) {
    #grid {
        padding: 0 2em 2em;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, 20vh);
    }
}