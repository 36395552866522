#header {
    width: 100%;
    height: 15vh;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 4em;
    box-sizing: border-box;
}

#header > span {
    font-family: 'De Valencia', sans-serif;
    font-size: 1.7em;
    text-transform: uppercase;
}

@media screen and (max-aspect-ratio: 3 / 4) {
    #header {
        padding: 0 2em;
    }

    #header > span {
        font-size: 1.5em;
    }
}